import axios from '@axios'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    load: false,
    tarefasDaSub: [],
    idSub: null,
  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },
    setTarefasGrupo(state, data) {
      state.tarefasDaSub = data
    },
    setIdSub(state, data) {
      state.idSub = data
    },
  },
  actions: {
    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/subdisciplinas/favoritos/${id}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },

    fetchDados({ commit }, queryParams) {
      commit('alteraLoading', true)

      return new Promise((resolve, reject) => {
        axios
          .get(`/subdisciplinas/${router.currentRoute.params.id}/favoritos`, { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    fetchDadosTarefas({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/subdisciplinas/${router.currentRoute.params.id}`, { params: queryParams })
          .then(response => {
            commit('setTarefasGrupo', response.data.tarefas)
            commit('setIdSub', response.data.uuid)
            resolve(response.data)
          })
          .catch(error => console.log(error))
      })
    },

    store(ctx, itemData) {
      const formData = new FormData()

      formData.append('nome', itemData.nome)

      itemData.tarefas.forEach(tarefa => {
        formData.append('tarefas[]', tarefa)
      })

      return new Promise((resolve, reject) => {
        axios
          .post(`/subdisciplinas/${itemData.idSub}/favoritos`, formData)
          .then(response => resolve(response => {
          }))
          .catch(error => reject(error))
      })
    },

    show(ctx, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/subdisciplinas/favoritos/${router.currentRoute.params.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update(ctx, itemData) {
      const formData = new FormData()
      formData.append('nome', itemData.nome)

      itemData.tarefas.forEach(tarefa => {
        formData.append('tarefas[]', tarefa)
      })

      formData.append('_method', 'PUT')
      return new Promise((resolve, reject) => {
        axios
          .post(`/subdisciplinas/favoritos/${itemData.uuid}`, formData)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
